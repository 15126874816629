<template>
    <div>
      <!-- <b-button v-b-modal.modal-prevent-closing>Open Modal</b-button> -->
      <b-modal
        id="modal-prevent-closing"
        ref="modal"
        :title="$parent.lang.discount"
        ok-only
        @ok="handleOk"
        :ok-title="$parent.lang.close"
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <label>{{$parent.lang.discount}}</label>
            <b-form-input
                :label="$parent.lang.discount"
                v-model="$parent.discount" 
                class="inborder"
                @change="checkDiscoutn()"
            />
        </form>
      </b-modal>
    </div>
  </template>
  
  <script>
    export default {
      data() {
        return {
          name: '',
          nameState: null,
          submittedNames: []
        }
      },
      methods: {
        checkDiscoutn(){
            
            if(this.$parent.discount > this.$parent.intotal)
            {
                this.$parent.discount = this.$parent.intotal;
            }
        },
        checkFormValidity() {
        //   const valid = this.$refs.form.checkValidity()
        //   this.nameState = valid
        //   return valid
        },
        resetModal() {
        //   this.name = ''
        //   this.nameState = null
        },
        handleOk(bvModalEvent) {
          // Prevent modal from closing
        //   bvModalEvent.preventDefault()
          // Trigger submit handler
        //   this.handleSubmit()
        },
        handleSubmit() {
          // Exit when the form isn't valid
        //   if (!this.checkFormValidity()) {
        //     return
        //   }
          // Push the name to submitted names
        //   this.submittedNames.push(this.name)
          // Hide the modal manually
        //   this.$nextTick(() => {
        //     this.$bvModal.hide('modal-prevent-closing')
        //   })
        }
      }
    }
  </script>
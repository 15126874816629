<template>
    <div class="messageShow">
        <div class="messageBox">
            <div v-if="errorMessage != ''">{{ errorMessage }}</div>
            <v-simple-table v-else>
                <thead>
                    <tr>
                        <th class="text-center backBlack" colspan="7">{{ lang.opening_balance }}</th>
                    </tr>
                    <tr>
                        <th class="backBlack text-center">{{ lang._total_1_ryals }}</th>
                        <th class="backBlack text-center">{{ lang._total_5_ryals }}</th>
                        <th class="backBlack text-center">{{ lang._total_10_ryals }}</th>
                        <th class="backBlack text-center">{{ lang._total_20_ryals }}</th>
                        <th class="backBlack text-center">{{ lang._total_50_ryals }}</th>
                        <th class="backBlack text-center">{{ lang._total_100_ryals }}</th>
                        <th class="backBlack text-center">{{ lang._total_500_ryals }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-center" style="padding:5px !important;">
                            <b-form-input class="inborder text-center" v-model="summ.s_1"></b-form-input>
                        </td>
                        <td class="text-center" style="padding:5px !important;">
                            <b-form-input class="inborder text-center" v-model="summ.s_5"></b-form-input>
                        </td>
                        <td class="text-center" style="padding:5px !important;">
                            <b-form-input class="inborder text-center" v-model="summ.s_10"></b-form-input>
                        </td>
                        <td class="text-center" style="padding:5px !important;">
                            <b-form-input class="inborder text-center" v-model="summ.s_20"></b-form-input>
                        </td>
                        <td class="text-center" style="padding:5px !important;">
                            <b-form-input class="inborder text-center" v-model="summ.s_50"></b-form-input>
                        </td>
                        <td class="text-center" style="padding:5px !important;">
                            <b-form-input class="inborder text-center" v-model="summ.s_100"></b-form-input>
                        </td>
                        <td class="text-center" style="padding:5px !important;">
                            <b-form-input class="inborder text-center" v-model="summ.s_500"></b-form-input>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th colspan="7" class="text-center backBlack" style="font-size:1.5rem">{{ remaintotal }} ريال</th>
                    </tr>
                </tfoot>
            </v-simple-table>        
        </div>
        <button class="btn btn-success buttonclass" style="left: 220px;" @click="openShift()">{{ lang.open_new_shift }}</button>
        <button class="btn btn-success buttonclass" style="background:red;" @click="$router.puhs({path: '/logout'})">{{ lang.logout }}</button>
  </div>
</template>


<script>
import axios from 'axios';
import {SnotifyPosition, SnotifyStyle} from 'vue-snotify';

export default{
    data() {
        return {
            errorMessage: '',
            showMessage: false,
            summ: {
                s_1: 0,
                s_5: 0,
                s_10: 0,
                s_20: 0,
                s_50: 0,
                s_100: 0,
                s_500: 0,
            },
            numbers: {
                s_1: 1,
                s_5: 5,
                s_10: 10,
                s_20: 20,
                s_50: 50,
                s_100: 100,
                s_500: 500,
            }
        }
    },
    computed: {
        remaintotal: function () {
            let t = 0;
            Object.keys(this.summ).forEach(key => {
                t = parseFloat(this.summ[key]) * parseFloat(this.numbers[key]) + parseFloat(t);
            })
            return t;
        },
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    },
    created() {
        // this.openGet()
    },
    methods: {
        openShift(){
            if(this.remaintotal < 0) return false;
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type", "openSheft");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            Object.keys(this.summ).forEach(key => {
                post.append('data['+key+']',this.summ[key]);
            })
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                if(response.data.error.number == 101){
                    this.errorMessage = "هناك وردية اخرى قيد العمل الان";
                }
                else{
                    this.$parent.showMessage = false;
                }
                
            })
        },
        openGet(){
            if(this.remaintotal < 0) return false;
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type", "getSheft");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            Object.keys(this.summ).forEach(key => {
                post.append('data['+key+']',this.summ[key]);
            })
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                if(response.data.results.data.userid != 0){
                    if(response.data.results.data.userid != response.data.results.myid){
                        this.errorMessage = 'هناك وردية بدأت العمل من قبل موظف آخر .. يرجى مراجعة الموظف واغلاق الورديه قبل تسجيل الدخول';
                    }else{
                        this.showMessage = false;
                    }
                }else{
                    this.showMessage = false;
                }
            })
        }
    },
}
</script>

<style scoped>
.messageShow{
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom: 0;
  display:flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  background: #fffffff2;
  border:1px solid #000000;
  color:#000000;
  z-index: 1000000;
}
.messageBox{
  
  width:700px;
  border: 2px solid #000;
  background: #d7b100;
  padding: 5px;
  text-align:center;
  border-radius: 10px;
}
.buttonclass{
  position:fixed;
  z-index: 1000;
  bottom: 50px;
  left:100px;
}
</style>
<template>

      <b-modal
        id="modalPOSAddNote"
        ref="modal2"
        :title="$parent.lang.invoice_notes"
        ok-only
        @ok="handleOk3"
        :ok-title="$parent.lang.save"
      >

        <div class="px-3 py-2" style="direction:rtl">
            <label>{{$parent.lang.invoice_notes}}</label>
            <b-form-textarea
                class="inborder"
                :label="$parent.lang.invoice_notes"
                v-model="$parent.notes" 
            />
        </div>

      </b-modal>

  </template>
  
  <script>
    export default {
      data() {
        return {
          name: '',
          nameState: null,
          submittedNames: []
        }
      },
      methods: {

        checkFormValidity() {
        //   const valid = this.$refs.form.checkValidity()
        //   this.nameState = valid
        //   return valid
        },
        resetModal() {
        //   this.name = ''
        //   this.nameState = null
        },
        handleOk3(bvModalEvent) {
          // Prevent modal from closing
        //   bvModalEvent.preventDefault()
          // Trigger submit handler
        //   this.handleSubmit()
        },
        handleSubmit() {
          // Exit when the form isn't valid
        //   if (!this.checkFormValidity()) {
        //     return
        //   }
          // Push the name to submitted names
        //   this.submittedNames.push(this.name)
          // Hide the modal manually
        //   this.$nextTick(() => {
        //     this.$bvModal.hide('modal-prevent-closing')
        //   })
        }
      }
    }
  </script>
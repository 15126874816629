<template>

      <b-modal
        id="modalPOSAddInvoice"
        ref="modal2"
        :title="$parent.lang.add_invoice"
      >
      <template #modal-header >
                <div class="w-100 flexit" style="direction:rtl;text-align:center">
                    <div style="color:#fff !important;margin-bottom:5px!important;font-size:1.3rem;border:2px solid #fff !important;width:150px;display:flex;justify-content: center;justify-items: center;align-items: center;">
                        {{ $parent.lang.add_invoice }}
                    </div>
                </div>
            </template>
        <div class="px-3 py-2" style="direction:rtl">
            <v-row>
                <v-col cols="6">
                    <label>المبلغ المدفوع نقدا</label>
                    <b-form-input
                        :label="$parent.lang.paid_amount"
                        v-model="cash"
                        class="inborder"
                        
                        >{{ $parent.paidamount }}</b-form-input>
                </v-col>
                <v-col cols="6">
                <label>المبلغ المدفوع شبكة</label>
                <b-form-input
                    :label="$parent.lang.paid_amount"
                    v-model="span"
                    class="inborder"
                    
                >{{ $parent.paidamount }}</b-form-input>
                </v-col>
                <v-col cols="6">
                <label>اجمالي المدفوع</label>
                <b-form-input
                    v-model="total_paid"
                    readonly
                    class="inborder"
                    
                >{{ $parent.paidamount }}</b-form-input>
                </v-col>
                <v-col cols="6">
                <label>المتبقي للعميل</label>
                <b-form-input
                    v-model="total_return"
                    readonly
                    class="inborder"
                    
                >{{ $parent.paidamount }}</b-form-input>
                </v-col>
            </v-row>
        </div>
        <template #modal-footer="{ close }" >
                <div class="w-100" style="direction:rtl;text-align:center;">
                    <b-button type="button" @click='cashSpan()' style="font-size:1.1rem;border:2px solid #fff !important;background:green !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ $parent.lang.add }}</b-button>
                    <b-button type="button" ref="adivncloseit" @click='close()' style="font-size:1.1rem;border:2px solid #fff !important;background:red !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ $parent.lang.close }}</b-button>
                </div>
            </template>
      </b-modal>

  </template>
  
  <script>
  
    export default {
      data() {
        return {
          name: '',
          nameState: null,
          submittedNames: [],
          cash: 0,
            span: 0
        }
      },
      computed:{
        total_paid: function(){
            let t=0;
            // if(!isNaN(this.cash)) {
            //   this.cash = 0;
            // }
            // if(!isNaN(this.span)) {
            //   this.span = 0;
            // }
            t = parseFloat(this.cash,2) + parseFloat(this.span,2);
            return t;
        },
        total_return: function(){
            let cash = this.cash == '' ? 0 : this.cash;
            let span = this.span == '' ? 0 : this.span;
            let t=0;
            t = parseFloat(this.total_paid,2) - parseFloat(this.$parent.paidamount,2);
            return t;
        },
      },
      methods: {
        cashSpan(){
            this.$parent.cash = this.cash == '' ? 0 : this.cash;
            this.$parent.span = this.span == '' ? 0 : this.span;
          if(this.$RoundNums(this.total_paid,0) < this.$RoundNums(this.$parent.paidamount,0))
          {
            this.$parent.invtype = 2;
          }
            this.$parent.addInvoice()
        
        },
        checkFormValidity() {
        //   const valid = this.$refs.form.checkValidity()
        //   this.nameState = valid
        //   return valid
        },
        resetModal() {
        //   this.name = ''
        //   this.nameState = null
        },
        handleOk3(bvModalEvent) {
          // Prevent modal from closing
        //   bvModalEvent.preventDefault()
          // Trigger submit handler
        //   this.handleSubmit()
        },
        handleSubmit() {
          // Exit when the form isn't valid
        //   if (!this.checkFormValidity()) {
        //     return
        //   }
          // Push the name to submitted names
        //   this.submittedNames.push(this.name)
          // Hide the modal manually
        //   this.$nextTick(() => {
        //     this.$bvModal.hide('modal-prevent-closing')
        //   })
        }
      }
    }
  </script>